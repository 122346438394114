import {RpcMetadata} from '@protobuf-ts/runtime-rpc';

export function parseCiamControlValues(
  headers: RpcMetadata
): Record<string, string> {
  const rawControlValues = headers['ciam-control-values'];
  const parsedControlValues: Record<string, string> = {};
  // the format of ciam-control-values should look like this:
  // {ciam-control-values:'key1=val2, key2=val2'}
  if (!(typeof rawControlValues == 'string')) {
    return parsedControlValues;
  }
  let controlValuesArray = rawControlValues
    .split(',')
    .map(keyValue => keyValue.trim());
  for (const keyValue of controlValuesArray) {
    const firstEqualSignIndex = keyValue.indexOf('=');
    if (firstEqualSignIndex < 1) {
      // TODO: notify CIAM if we get here
      continue;
    }
    parsedControlValues[keyValue.substring(0, firstEqualSignIndex)] =
      keyValue.substring(firstEqualSignIndex + 1);
  }
  return parsedControlValues;
}
