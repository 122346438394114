import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
export function setPsatCookie(token: string, expires: string) {
  const expirationSeconds = parseInt(expires);
  if (!isNaN(expirationSeconds)) {
    const expirationDate = new Date(expirationSeconds * 1000);
    const envPsatKey: string = config.getString('PSAT_COOKIE_KEY');
    document.cookie = `${envPsatKey}=${token}; expires=${expirationDate.toUTCString()}; domain=${getDomainName(
      window.location.hostname
    )}; path=/`;
  }
}

export function getPsatFromCookie(): string | undefined {
  const envPsatKey: string = config.getString('PSAT_COOKIE_KEY');
  const value = '; ' + document.cookie;
  const parts = value.split(`; ${envPsatKey}=`);

  if (parts.length == 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
}

export function deletePsatFromCookie() {
  const envPsatKey: string = config.getString('PSAT_COOKIE_KEY');
  const epochUTC = new Date(0).toUTCString();
  document.cookie = `${envPsatKey}=; expires=${epochUTC}; domain=${getDomainName(
    window.location.hostname
  )}; path=/`;
}

// This function will return the `${SLD}.${TLD}`
// we assume the top level domain only consist one part
// eg. if hostname = dev.app.verilyme.com
// This function will return you verilyme.com
function getDomainName(hostname: string) {
  if (hostname === 'localhost') {
    return hostname;
  }
  const hostnameParts: string[] = hostname.split('.');
  const domainName: string = hostnameParts.slice(-2).join('.');
  return domainName;
}
